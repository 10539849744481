import brain from './brain.png'
import ideas from './ideas.png'
import automation from './automation.png'

let img = new Image()
img.src = brain;

let ideaImg = new Image()
ideaImg.src = ideas

let autoImg = new Image()
autoImg.src = automation

const drawTextEffect = (ctx, canvas) => {

    let particleArray = []
    let particlesArray = []
    let adjustX = 50
    let adjustY = 10

    const mouse = {
        x: null,
        y: null,
        radius: 60
    }

    window.addEventListener('mousemove', (event) => {
        mouse.x = event.x
        mouse.y = event.y
    })

    window.addEventListener('mouseout', () => {
        mouse.x = undefined
        mouse.y = undefined
    })

    window.addEventListener('resize', () => {
        mouse.radius = 100 //((canvanHeight/80) * (window.innerWidth/80))
        console.log("rezise")
        init()
    })

    ctx.drawImage(img, 50, 100, 50, 50)
    ctx.drawImage(ideaImg, 100, 100, 50, 50)
    ctx.drawImage(autoImg, 150, 100, 50, 50)

    ctx.fillStyle = "white"
    // ctx.font = "30px Verdana"
    ctx.strokeStyle = "white"
    // ctx.strokeRect(winPosX , winPosY, 100, 100);
    // ctx.fillText('PiFold', 0, 40)
    ctx.font = "20px Verdana"
    let ctext = "We make magic things".split("").join(String.fromCharCode(8202))
    ctx.fillText(ctext, 0, 40);
    let ctext2 = "with the data and AI".split("").join(String.fromCharCode(8202))
    ctx.fillText(ctext2, 0, 70);
    let textCoordinates = ctx.getImageData(0 , 0, 300, 300)

    class ParticleDots {
        constructor(x, y, directionX, directionY, size, color){
            this.x = x;
            this.y = y;
            this.directionX = directionX;
            this.directionY = directionY;
            this.size = size;
            this.color = color;
        }
        // function to draw individual particle
        draw() {
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
            ctx.fillStyle = 'white';
            ctx.fill();
            ctx.strokeStyle = 'black' //'#5c9ed8';
            ctx.stroke();
        }
        // check particle position, check mouse position, move the particle, draw the particle
        update () {
            // check if particle is still whithin canvas
            if (this.x > canvas.width || this.x < 0) {
                this.directionX = -this.directionX;
            }
            if (this.y > canvas.height || this.y < 0) {
                this.directionY = -this.directionY;
            }
            //check collision detection - mouse position / particle position
            let dx = mouse.x - this.x;
            let dy = mouse.y - this.y;
            let distance = Math.sqrt(dx*dx + dy*dy)
            if (distance < mouse.radius + this.size) {
                if (mouse.x < this.x && this.x < canvas.width - this.size * 10) {
                    this.x += 10;
                }
                if (mouse.x > this.x && this.x > this.size * 10) {
                    this.x -= 10;
                }
                if (mouse.y < this.y && this.y < canvas.height - this.size * 10 ) {
                    this.y += 10;
                }
                if (mouse.y > this.y && this.y > this.size * 10) {
                    this.y -= 10;
                }
            }
            // move particle
            this.x += this.directionX;
            this.y += this.directionY;
            // draw particle
            this.draw()
        }
    }

    class Particle{
        constructor(x, y){
            this.x = x;
            this.y = y;
            this.zise = 3;
            this.baseX = this.x;
            this.baseY = this.y;
            this.dencity = (Math.random() * 40) + 5;
        }

        draw(){
            ctx.fillStyle = 'white';
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.zise, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();
        }
        update(){
            let dx = mouse.x - this.x
            let dy = mouse.y - this.y
            let distance = Math.sqrt(dx*dx + dy*dy)
            let forceDirectionX = dx / distance
            let forceDirectionY = dy / distance
            let maxDistance = mouse.radius
            let force = (maxDistance - distance) / maxDistance
            let directionX = forceDirectionX * force * this.dencity
            let directionY = forceDirectionY * force * this.dencity

            if(distance < mouse.radius){
                // this.zise = 50
                this.x -= directionX
                this.y -= directionY
            }else{
                if(this.x !== this.baseX){
                    let dx = this.x - this.baseX
                    this.x -= dx/2
                }
                if(this.y !== this.baseY){
                    let dy = this.y - this.baseY
                    this.y -= dy/2
                }
                // this.zise = 30
            }
        }
    }

    const init = () => {
        for(let y = 0, y2 = textCoordinates.height; y < y2; y++){
            for(let x = 0, x2 = textCoordinates.width; x < x2; x++){
                if(textCoordinates.data[((y * 4 * (textCoordinates.width)) + (x * 4) + 3)] > 128){
                    let positionX = x + adjustX
                    let positionY = y + adjustY
                    particleArray.push(new Particle(positionX * 4, positionY * 4))
                }
            }
        }
        // for(let i=0; i< 1000; i++){
        //     let x = Math.random() * canvas.width
        //     let y = Math.random() * canvas.height
        //     particleArray.push(new Particle(x, y))
        // }
        // dots
        // let numberOfParticles = (canvas.height * canvas.width) / 8000;
        // for (let i = 0; i < numberOfParticles * 1.5; i++) {
        //     let size = (Math.random() * 5) + 1;
        //     let x = (Math.random() * ((canvas.width - size * 2) - (size * 2)) + size * 2);
        //     let y = (Math.random() * ((canvas.height - size * 2) - (size * 2)) + size * 2);
        //     let directionX = (Math.random() * 5) - 2.5;
        //     let directionY = (Math.random() * 5) - 2.5;
        //     let color = '#5c9ed8';

        //     particlesArray.push(new ParticleDots(x, y, directionX, directionY, size, color));
        // }
    }

    const connect = () => {
        for( let a = 0; a < particleArray.length; a++){
            for( let b = 0; b < particleArray.length; b++){
                let dx = particleArray[a].x - particleArray[b].x
                let dy = particleArray[a].y - particleArray[b].y
                let distance = Math.sqrt(dx*dx + dy*dy)
                if (distance < 6) {
                    let number = (Math.floor(Math.random() * 10) + 1)
                    // console.log(number)
                    ctx.strokeStyle = 'rgb(0, 0, 0, 1)';
                    // if ( number > 5){
                    //     ctx.strokeStyle = 'rgb(92, 158, 216, 1)';
                    // }else{
                    //     ctx.strokeStyle = 'rgb(24, 50, 97, 0.5)';
                    // }
                    ctx.lineWith = 2;
                    ctx.beginPath();
                    ctx.moveTo(particleArray[a].x, particleArray[a].y);
                    ctx.lineTo(particleArray[b].x, particleArray[b].y);
                    ctx.stroke();
                }
            }
        }
        // dots
        // let opacityValue = 0.1;
        // for( let a = 0; a < particlesArray.length; a++){
        //     for( let b = 0; b < particlesArray.length; b++){
        //         let distace = ((particlesArray[a].x - particlesArray[b].x) * (particlesArray[a].x - particlesArray[b].x)) +
        //                         ((particlesArray[a].y - particlesArray[b].y) * (particlesArray[a].y - particlesArray[b].y));
        //         if (distace < (canvas.width/14) * (canvas.height/14)) {
        //         // if (distace < 1000) {
        //             let number = (Math.floor(Math.random() * 10) + 1)
        //             // console.log(number)
        //             if ( number > 5){
        //                 ctx.strokeStyle = 'rgb(92, 158, 216, 1)';
        //             }else{
        //                 ctx.strokeStyle = 'rgb(24, 50, 97, '+opacityValue+')';
        //             }
        //             ctx.lineWith = 1;
        //             ctx.beginPath();
        //             ctx.moveTo(particlesArray[a].x, particlesArray[a].y);
        //             ctx.lineTo(particlesArray[b].x, particlesArray[b].y);
        //             ctx.stroke();
        //         }
        //     }
        // }
    }

    const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        for(let i = 0; i < particleArray.length; i++){
            particleArray[i].draw()
            particleArray[i].update()
        }
        for (let i = 0; i < particlesArray.length; i++) {
            particlesArray[i].update();
        }
        connect()
        requestAnimationFrame(animate)
    }

    init()
    animate()
}

export default drawTextEffect; 