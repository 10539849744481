import React, { useEffect, useState, useRef } from 'react'
// import {
//     Switch, Route, HashRouter,
//     Redirect, withRouter
//   } from 'react-router-dom'
import { Parallax, ParallaxLayer} from '@react-spring/parallax'
import styles from './styles.module.css'
import './styles.module.css'
import alina from './brian-mc.jpg'
import pifod from './pifoldlogo.png'
import Canvas from "./../components/Canvas";

// import drawImageEffect from '../particles/ImageEffect';
import drawTextEffect from '../particles/TextEffext';
// import drawParticles from '../particles/ParticleEffect';

const Main = (props) => {
    const alignCenter = { display: 'flex', alignItems: 'center' }
    const canvanWidth = window.innerWidth
    const [canvanHeight, setCanvanHeight] = useState(350);
    const parallax = useRef();

    useEffect(() => {
        setCanvanHeight(parallax.current.space)
        return () => {  
        }
    }, [])

    const nextPage = (page) => {
        console.log("parallax");
        console.log(parallax.current);
        parallax.current.scrollTo(page);
    }

    return (
        <div>
             <div className={styles.background} />
                <div className={styles.buttons}>
                    <button className={`${styles.buttonmenu} buttonmenu1`} onClick={() => nextPage(0)}>
                    </button>
                    <button className={`${styles.buttonmenu} buttonmenu2`} onClick={() => nextPage(1)}></button>
                    {/* <button className={`${styles.buttonmenu} buttonmenu3`}></button> */}
                </div>
                <Parallax ref={parallax} pages={2} style={{ top: '0', left: '0' }}>
                    {/* <ParallaxLayer offset={0} speed={0}  
                       style={{ ...alignCenter, justifyContent: 'center', alignItems: 'center', backgroundColor: '#87BCDE', backgroundImage: `url(${brain})` }}
                    > */}
                    <ParallaxLayer offset={0} speed={0}  
                       style={{ ...alignCenter, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}
                    >
                        <Canvas draw={drawTextEffect} height={canvanHeight} width={canvanWidth} className={'canvas1'} />
                    </ParallaxLayer>

                    {/* <ParallaxLayer offset={0} speed={0.5} style={{ ...alignCenter, justifyContent: 'center' }}>
                        <p className={styles.scrollText}>We make magic thinks</p>
                    </ParallaxLayer> */}

                    <ParallaxLayer offset={1} speed={0} 
                        style={{ ...alignCenter, 
                                 justifyContent: 'center', 
                                 backgroundColor: 'white',
                                 backgroundImage: `url(${alina})`,
                                 backgroundRepeat: 'no-repeat',
                                 backgroundSize: 'cover' 
                              }}
                    >
                        {/* <Canvas draw={drawParticles} height={canvanHeight} width={canvanWidth} className={'canvas1'} /> */}
                    </ParallaxLayer>
                    <ParallaxLayer offset={0.9} speed={0} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
                        <div className={`${styles.card} ${styles.parallax} ${styles.sticky}`}>
                            
                            <p> <img src={pifod} alt="pifold"/>We are a company focused on research, innovation and application of artificial intelligence, data science and the internet of things (IoT) to different sectors of the industry.</p>
                        </div>
                    </ParallaxLayer>

                    {/* <ParallaxLayer sticky={{ start: 1, end: 3 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
                        <div className={`${styles.card} ${styles.sticky}`}>
                        <p>I'm a sticky layer</p>
                        </div>
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.5} speed={1.5} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
                        <div className={`${styles.card} ${styles.parallax} ${styles.blue}`}>
                        <p>Neither am I</p>
                        </div>
                    </ParallaxLayer> */}
                </Parallax>
        </div>
    )
}

export default Main